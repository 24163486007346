import Modal from './Modal';

export default function DeleteModal({open, close,itemName, onDelete}) {
  
  return(<Modal 
        width={`max-w-sm`} 
        modalHeader={`Delete ${itemName}`} 
        open={open} 
      >
        <div className="mt-4 relative p-6 flex-auto">
          <div className="pb-4">
              <p className="text-gray-700 text-lg">Are you sure ?</p>
              <p className="text-gray-500 text-sm">You won't be able to revert this!</p>
          </div>
        </div>
        <div className="flex py-4 px-6 justify-end">
          <button onClick={close} type="button" className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm">
              <span>No</span>
          </button>
          <button onClick={onDelete} type="button" className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none sm:text-sm">
              <span>Yes</span>
          </button>
        </div>
      </Modal>)
}