import React from 'react';
import { NavLink } from "react-router-dom";
import ChatIcon from '../../utils/ChatIcon';
import DocumentTextIcon from '../../utils/DocumentTextIcon';
import WalletIcon from '../../utils/WalletIcon';
import BanknotesIcon from '../../utils/BanknotesIcon';
import BriefcaseIcon from '../../utils/BriefcaseIcon';
import UserGroupIcon from '../../utils/UserGroupIcon';
import UsersIcon from '../../utils/UsersIcon';
import TicketIcon from '../../utils/TicketIcon';
import HomeIcon from '../../utils/HomeIcon';


const SideNavbar = ({handleSideNav}) => {
  return (
    <div className="py-7 pl-2">
      <div className="h-10"></div>
      {/* nav links  */}
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='/dashboard' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <HomeIcon/>
          Dashboard
        </NavLink>
      </nav>
      <nav>
        <NavLink  
          onClick={handleSideNav}
          to='/events' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <TicketIcon/>
          Events
        </NavLink>
      </nav>
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='/users' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <UsersIcon/>
          Users
        </NavLink>
      </nav>
      <nav>
        <NavLink  
          onClick={handleSideNav}
          to='/committees' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <UserGroupIcon/>
          Committees
        </NavLink>
      </nav>
      <nav>
        <NavLink  
          onClick={handleSideNav}
          to='/contributions' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <WalletIcon/>
          Contributions
        </NavLink>
      </nav>
      <nav>
        <NavLink  
          onClick={handleSideNav}
          to='/service-providers' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <BriefcaseIcon/>
          Service Providers
        </NavLink>
      </nav>
      <nav>
        <NavLink  
          onClick={handleSideNav}
          to='/budgets' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <BanknotesIcon/>
          Budgets
        </NavLink>
      </nav>
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='/sms-contents' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <ChatIcon/>
          SMS
        </NavLink>
      </nav>
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='/reports' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          <DocumentTextIcon/>
          Reports
        </NavLink>
      </nav>
    </div>
  )
}

export default SideNavbar;