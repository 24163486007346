import { NavLink } from "react-router-dom"
import UserCircle from "../../utils/UserCircle"

const AdminSideNavbar = ({handleSideNav}) => {
  const user = localStorage.getItem('user');
  return (
    <div className="py-7 pl-2">
      <div className="h-10"></div>  
      {/* nav links  */}
      <nav>
        <NavLink
          onClick={handleSideNav}  
          to='/administrator/dashboard' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >Dashboard</NavLink>
      </nav>
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='/administrator/events' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >Events</NavLink>
      </nav>
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='/administrator/users' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >Users</NavLink>
      </nav>
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='administrator/sms-bundles' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >SMS Bundles</NavLink>
      </nav>
      <nav>
        <NavLink 
          onClick={handleSideNav} 
          to='administrator/regions' 
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >Regions</NavLink>
      </nav>
    </div>
  )
}

export default AdminSideNavbar;