import React from 'react';
import { ThreeCircles } from  'react-loader-spinner';

function Loader() {
  return (
    <ThreeCircles
      height="80"
      width="80"
      color="#ffcc00"
      wrapperStyle={{position: 'absolute', left: '50%', top: '50%',zIndex: '30',}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
    
  )
}

export default Loader