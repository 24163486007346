import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import useAuthAxios from '../../hooks/useAuthAxios';
import useAxios from '../../hooks/useAxios';
import Modal from '../../utils/Modal';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import "react-datepicker/dist/react-datepicker.css";
import IF from '../../utils/IF';
import AddIcon from '../../utils/AddIcon';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Loader from '../../hocs/Loader';

const EventSelect = () => {
  const compname = "Event";
  const app_url = "events";
  const axios = useAuthAxios();
  // eslint-disable-next-line
  const [response, error, loading, axiosFetch, responseData] = useAxios();
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, reset, control, formState:{errors}} = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const [ loadingSpin, setLoadingSpin ] = useState(false);
  // eslint-disable-next-line
  const [inputValue, setInputValue] = useState('');
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState({
     value: 10, 
     label: 10 
  });
  
  const eventOptions = responseData.map(item =>{
    return {
      label:`${item.name} | ${item?.region?.name} | ${format(new Date(item.date),'dd-MM-yyyy')}`,
      value: item
    }
  });

  const onChangeSelect = (e)=>{
    localStorage.setItem("event_name",e.value.name)    
    localStorage.setItem("id",e.value.id)    
    const url = `event/${e.value.id}/use-event`;
    const method = 'POST';
    postSelected(url,method );
  }
  
  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: 'GET',
      url: `${app_url}?page_size=20`,
    })
  }

  useEffect(() =>{
    getData();
    // eslint-disable-next-line
  },[]);

  const resetForm = () => {
    reset({
      name:"",
      region:"",
      date:""
    });
    setIsEdit(false);
    setOpen(false);
  };

  const onSubmit = (data) => {
    data.date =format(data.date, 'yyyy-MM-dd');
    data.region = data.region?data.region.id:null;
    const url = `${app_url}`;
    const method = 'POST';
    postData(data,url,method );
  }

  const postData = async (data,url,method ) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url,data).then(
      (result)=>{
        setLoadingSpin(false);
        resetForm();
        getData();
        toast.success(result.data.message)
      },
      (error)=>{
        setLoadingSpin(false);
        const errorData = JSON.stringify(error.response.data);
        if (error.response.status === 403) {
          toast.error(error.response.data.detail);
        } else {
          toast.error(errorData);
        }
      }
    );
  }

  const postSelected = async (url,method ) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url).then(
      (result)=>{
        setLoadingSpin(false);
        navigate('/dashboard', {replace: true});
        toast.success(result.data.message)
      },
      (error)=>{
        setLoadingSpin(false);
        const errorData = JSON.stringify(error.response.data);
        toast.error(errorData);
      }
    );
  }

  const getRegions = async (inputValue ) => {
    const url = `regions?page_size=${pageSize.value}&search=${inputValue}`;
    const method = 'GET';
    setLoadingSpin(true);
    return await axios[method.toLowerCase()](url).then(
      (result)=>{
        setLoadingSpin(false);
        if (result.status === 200) {
          if (result.data.status === 400){
            toast.error(result.data.message)
          }else{
            return result.data.results
          }
        }
      },
      (error)=>{
        setLoadingSpin(false);
        const errorData = JSON.stringify(error.response.data);
        toast.error(errorData);
      }
    );
  }

  const handleInputChange = value => {
    setInputValue(value);
  };
    
  return (
    <>
      {(loading || loadingSpin) && <Loader/>}
      <section 
        className="rounded bg-white
        shadow-md w-full h-full
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
        </div>
        <div className="flex justify-end">
          {!responseData.length > 0 && <button onClick={() => setOpen(true)} title="Add" className="m-1 px-2 py-2 border border-green-500 rounded text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none ">
              <AddIcon/>
          </button>}
        </div>
        <div>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-8 p-6 special_h">
            {!loading && !error && !responseData.length > 0 && <p>No Data to Display,Please Create New Event</p>}
            <IF condition={responseData.length > 0}>
              <div className="lg:w-1/2 md:w-1/2 flex-auto">
                <label className="relative">
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      border: 1,
                      colors: {
                      ...theme.colors,
                      text: 'gray',
                      primary25: 'lightgray',
                      primary: '#4b5563',
                    }})}
                    classNamePrefix="mySelect"
                    defaultValue={null}
                    onChange={(e)=>{
                      onChangeSelect(e);
                    }}
                    options={eventOptions}
                  />
                  <span className={`
                          placeholder-shown:hidden
                        focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                          text-base text-gray-600
                          text-opacity-80  left-0 top-4
                          mx-2 px-2 absolute 
                          transition duration-200`}
                        >Select event</span>
                </label>
                
              </div>
              <div className="h-1/2"></div>
            </IF>
            
          </div>
        </div>
        <Modal 
          modalHeader={isEdit?`Edit ${compname}`:`New ${compname}`} 
          open={open} 
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 relative p-6 flex-auto">
              <div className="grid grid-cols-1 pb-6">
                <label className="relative">
                  <input 
                    type="text" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.name?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          } 
                    placeholder="Event name"
                    {...register('name',{required:true ,pattern:/^[a-zA-Z\s]+$/})}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">Event name</span>
                  {errors.name && errors.name.type === "required" && <p className="text-red-600 p-2 font-light">This field is required</p>}
                  {errors.name && errors.name.type === "pattern" && <p className="text-red-600 p-2 font-light">Only alphabet characters are allowed</p>}
                </label>
              </div>
              <div className="grid grid-cols-1 pb-6">
                <label className="relative">
                    <Controller
                      name="region"
                      control={control}
                      defaultValue={null}
                      rules={{required:false}}
                      render={
                        ({field})=>(
                          <AsyncSelect
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 6,
                              border: 1,
                              colors: {
                              ...theme.colors,
                              text: 'gray',
                              primary25: 'lightgray',
                              primary: '#4b5563',
                            }})}
                            classNamePrefix="mySelect"
                            {...field}
                            isClearable
                            cacheOptions={true}
                            defaultOptions={false} 
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e.id}
                            loadOptions={getRegions}
                            onInputChange={handleInputChange}
                            className={
                            `text-gray-700 text-base font-light w-full
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.region?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }  
                          />
                        ) 
                      }
                    />
                    <span className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-600
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                          >Region</span>
                    {errors.region && <p className="text-red-600 p-2 font-light">This field is required</p>}
                  </label>
              </div>
              <div className="grid grid-cols-1 pb-6">
                  <Controller
                    name="date"
                    control={control}
                    defaultValue={null}
                    rules={{required:true}}
                    render={
                      ({field})=>(
                        <label className="relative" onClick={e => e.preventDefault()}>
                          <DatePicker 
                            className={
                              `text-gray-700 text-base font-light h-12 w-full px-4
                              border-1 rounded-md border-opacity-50 outline-none
                              ${errors.date?'border-red-600 focus:border-red-600':'border-gray-600'} 
                              focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                              transition duration-200`
                            }
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            shouldCloseOnSelect={true}
                            minDate={new Date()}
                            dateFormat ="dd/MM/yyyy"
                            placeholderText='Select event date' 
                          />
                          <span className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-900
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                          >Date</span>
                        </label>
                      ) 
                    }
                  />
                  
                  {errors.date && <p className="text-red-600 p-2 font-light">This field is required</p>}
                
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button onClick={()=>resetForm()} type="button" className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm">
                  <span>Cancel</span>
              </button>
              <button type="submit" className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none sm:text-sm">
                  <span>{isEdit?'Update':'Create'}</span>
              </button>
            </div>
          </form>
        </Modal>
      </section>
    </>
  )
};
export default EventSelect;