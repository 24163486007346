import {useState,useEffect} from 'react';
import useRefresh from '../../hooks/useRefresh';
import useAuth from '../../hooks/useAuth';
import { Outlet, useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

const PersistLogin = () =>{
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefresh();
  const {auth} = useAuth();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0)

  useEffect(()=>{
    const verifyToken = async () =>{
      try { 
        await refresh();
      }
      catch (err) {
        if (err?.response.status === 400) {
          navigate('/login',{replace: true});
        }
      }
      finally {
        setIsLoading(false);
      }
    }
    !auth?.accessToken ? verifyToken(): setIsLoading(false);
  },[auth, refresh, navigate]);

  return (
    <>
    {isLoading
      ?
        <LoadingBar
        color='#FBBF24'
        progress={100}
        height={6}
        onLoaderFinished={() => setProgress(0)}
      /> 
      :<Outlet/>
    }
    </>
  )
}

export default PersistLogin;