import {useEffect, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import useAuthAxios from '../../hooks/useAuthAxios';
import useAxios from '../../hooks/useAxios';
import Modal from '../../utils/Modal';
import { toast } from 'react-toastify';

import "react-datepicker/dist/react-datepicker.css";
import Pagination from '../../utils/Pagination';
import ChevronLeft from '../../utils/ChevronLeft';
import ChevronRight from '../../utils/ChevronRight';
import DeleteIcon from '../../utils/DeleteIcon';
import EditIcon from '../../utils/EditIcon';
import IF from '../../utils/IF';
import AddIcon from '../../utils/AddIcon';
import ChevronDoubleLeft from '../../utils/ChevronDoubleLeft';
import ChevronDoubleRight from '../../utils/ChevronDoubleRight';
import SearchIcon from '../../utils/SearchIcon';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DeleteModal from '../../utils/DeleteModal';
import { NumberWithCommas, NumberWithoutCommas } from '../../utils/Helpers';
import Loader from '../../hocs/Loader';

const Budget = () => {
  const compname = "Budget";
  const app_url = "budgets";
  const axios = useAuthAxios();
  const [response, error, loading, axiosFetch, responseData] = useAxios();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [id, setId] = useState('');
  const [search, setSearch] = useState('');
  const { register, handleSubmit, reset, setValue, control, formState:{errors}} = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [ loadingSpin, setLoadingSpin ] = useState(false);
  
  const event_id = localStorage.getItem('id');
  const [pageSize, setPageSize] = useState({
     value: 10, 
     label: 10 
  });

  const handleInputChange = value => {
    setInputValue(value);
  };

  const pageSizeOptions = [
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 100, label: 100 }
  ];

  const onChangePageSize = (e)=>{
    setPageSize(e)
    axiosFetch({
      axiosInstance: axios,
      method: 'GET',
      url: `${app_url}?page_size=${e.value}&search=${search}&event=${event_id}`,
    })
  }
  
  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: 'GET',
      url: `${app_url}?page_size=${pageSize.value}&search=${search}&event=${event_id}`,
    })
  }

  useEffect(() =>{
    getData();
    // eslint-disable-next-line
  },[]);

  const resetForm = () => {
    reset({
      name:"",
      unit_price:"",
      quantity:"",
    });
    setIsEdit(false);
    setOpen(false);
  };

  const onSubmit = (data) => {
    data.event = event_id;
    data.unit_price = NumberWithoutCommas(data.unit_price);
    if (data.id) {
      data.service_provider = data.service_provider?data.service_provider.id:null;
      data.actual_amount = NumberWithoutCommas(data.actual_amount);
      const url = `${app_url}/${data.id}`;
      const method = 'PUT';
      postData(data,url,method );
    } else {
      const url = `${app_url}`;
      const method = 'POST';
      postData(data,url,method );
    }
  }

  const onShowEditModal = (data) => {
    const fields = [
      'id',
      'name',
      'unit_price',
      'quantity',
      'actual_amount',
      'service_provider',
    ];
    fields.forEach(field =>setValue(field,data[field]));
    setIsEdit(true);
    setOpen(true);
  }

  const onShowDeleteModal = (id) => {
    setId(id);
    setOpenDelete(true);
  }

  const onDelete = () => {
    const url = `${app_url}/${id}`;
    const method = 'DELETE';
    postData('',url,method );
  }

  const fetchPage = (pageNumber) => {
    const new_url = `${app_url}?page=${pageNumber}&page_size=${pageSize.value}&search=${search}&event=${event_id}`

    axiosFetch({
      axiosInstance: axios,
      method: 'GET',
      url: `${new_url}`,
    })
  }

  const postData = async (data,url,method ) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url,data).then(
      (result)=>{
        setLoadingSpin(false);
        toast.success(result.data.message)
        resetForm();
        getData();
        setOpenDelete(false);
      },
      (error)=>{
        setLoadingSpin(false);
        const errorData = JSON.stringify(error.response.data);
        if (error.response.status === 403) {
          toast.error(error.response.data.detail);
        } else {
          toast.error(errorData);
        }
      }
    );
  }

  const handleSearch = (e)=>{
    const inputContent = e.target.value.toLowerCase();
    setSearch(inputContent);
    
  }

  const onSearch = ()=>{
    getData();
  }
   
  const getServiceProviders = async (inputValue ) => {
    const url = `service-providers?page_size=${pageSize.value}&search=${inputValue}`;
    const method = 'GET';
    setLoadingSpin(true);
    return await axios[method.toLowerCase()](url).then(
      (result)=>{
        setLoadingSpin(false);
        if (result.status === 200) {
          if (result.data.status === 400){
            toast.error(result.data.message)
          }else{
            return result.data.results
          }
        }
      },
      (error)=>{
        setLoadingSpin(false);
        const errorData = JSON.stringify(error.response.data);
        toast.error(errorData);
      }
    );
  }

  return (
    <>
      {(loading || loadingSpin) && <Loader/>}
      <section 
        className="rounded bg-white 
        shadow-md w-full  
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
        </div>
        <div className="flex justify-end">
            <button onClick={() => setOpen(true)} title="Add" className="m-1 px-2 py-2 border border-green-500 rounded text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none ">
                <AddIcon/>
            </button>
        </div>
        <div>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-8 p-6">
            <div className="py-4 flex flex-wrap  gap-y-4">
              <div className="flex-auto w-64">  
                <label className="mb-2 text-sm font-normal text-gray-900 sr-only dark:text-gray-300">Search</label>
                <div className="relative">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <SearchIcon/>
                    </div>
                    <input 
                      type="search" 
                      className="block p-4 pl-10 
                        w-full text-base text-gray-900
                        border-1 rounded-md border-opacity-50 border-gray-600
                        focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1"
                      placeholder="Search..." 
                      onChange = {e => handleSearch(e)}
                      required 
                    />
                    <button 
                      type="submit" 
                      onClick={() => onSearch()}
                      className="text-white absolute 
                        right-2.5 bottom-2.5 bg-blue-700 
                        hover:bg-blue-800 focus:outline-none  
                        font-medium rounded-md text-sm px-4 py-2 
                        "
                    >Search</button>
                </div>
              </div>
              <div className="flex-auto w-32">
                <div className="flex justify-end">
                  <p className="px-4 text-sm text-gray-600">Rows per page:</p>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      border: 1,
                      colors: {
                      ...theme.colors,
                      text: 'gray',
                      primary25: 'lightgray',
                      primary: '#4b5563',
                    }})}
                    defaultValue={pageSize}
                    onChange={(pageSize)=>{
                      onChangePageSize(pageSize);
                    }}
                    options={pageSizeOptions}
                  />
                </div>
              </div> 
            </div>
            {!loading && !error && !responseData.length > 0 && <p>No Data to Display</p>}
            <IF condition={responseData.length > 0}>
              <table  className="min-w-full divide-y divide-gray-200">
                <thead  className="bg-gray-50">
                  <tr className="text-gray-700 uppercase tracking-wider">
                    <th className="px-4 py-3 text-left text-xs">Name</th>
                    <th className="px-4 py-3 text-left text-xs">Service Provider</th>
                    <th className="px-4 py-3 text-left text-xs">Quantity</th>
                    <th className="px-4 py-3 text-right text-xs">Unit price</th>
                    <th className="px-4 py-3 text-right text-xs">Budgeted amount</th>
                    <th className="px-4 py-3 text-right text-xs">Actual Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {responseData.map((data, i) =>
                  <tr key={i}  
                    className="whitespace-no-wrap
                    text-sm text-gray-600 py-4"
                  >
                    <td className="px-4 py-3">{ data.name }</td>
                    <td className="px-4 py-3">{ data.service_provider?.name }</td>
                    <td className="px-4 py-3">{ data.quantity }</td>
                    <td className="px-4 py-3 text-right">{ NumberWithCommas(data.unit_price) }</td>
                    <td className="px-4 py-3 text-right">{ NumberWithCommas(data.budgeted_amount) }</td>
                    <td className="px-4 py-3 text-right">{ NumberWithCommas(data.actual_amount) }</td>
                    <td className="flex">
                      <button 
                        onClick={() => onShowEditModal(data)} 
                        title="Edit" 
                        type="button" 
                        className="m-1 px-2 py-2 border 
                        border-blue-500 rounded 
                        text-sm font-medium 
                        text-white bg-blue-500 
                        hover:bg-blue-600 focus:outline-none "
                      >
                        <EditIcon/>
                      </button>
                      <button 
                        onClick={() => onShowDeleteModal(data.id)} 
                        title="Delete" 
                        type="button" 
                        className="m-1 px-2 py-2 border 
                          border-red-600 rounded text-sm 
                          font-medium text-white 
                          bg-red-600 hover:bg-red-700 
                          focus:outline-none "
                      >
                        <DeleteIcon/>
                      </button>
                    </td>
                  </tr>)}
                </tbody>
              </table>
              <Pagination data={response} >
                <div 
                  onClick={() => fetchPage(response.first)} 
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-l-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${response.current !== response.first?'text-gray-500':'text-gray-200'} 
                  ${response.current !== response.first?'hover:bg-gray-100':'hover:bg-white'} `}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeft/>
                </div>
                <div 
                  onClick={() => fetchPage(response.current - 1)} 
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${response.previous?'text-gray-500':'text-gray-200'} 
                  ${response.previous?'hover:bg-gray-100':'hover:bg-white'} `}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft/>
                </div>              
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  Page {response.current} of {response.last}
                </span>
                <div 
                  onClick={() => fetchPage(response.current + 1)} 
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${response.next?'text-gray-500':'text-gray-200'}
                  ${response.next?'hover:bg-gray-100':'hover:bg-white'}`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight />
                </div>
                <div 
                  onClick={() => fetchPage(response.last)} 
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-r-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${response.current !== response.last?'text-gray-500':'text-gray-200'}
                  ${response.current !== response.last?'hover:bg-gray-100':'hover:bg-white'}`}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRight />
                </div>
              </Pagination>
            </IF>
            
          </div>
        </div>
        <Modal 
          modalHeader={isEdit?`Edit ${compname}`:`New ${compname}`} 
          open={open}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 relative p-6">
              <div className="grid grid-cols-1 pb-6 gap-6">
                <label className="relative">
                  <input 
                    type="text" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.name?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }
                    placeholder="Name" 
                    {...register('name',{required:true})}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">Name</span>
                  {errors.name && <p className="text-red-600 p-2 font-light">This field is required</p>}
                </label>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 pb-6 gap-6">
                <label className="relative">
                  <input 
                    type="text" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.quantity?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }
                    placeholder="Quantity"
                    {...register('quantity',{required:true})}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">Quantity</span>
                  {errors.quantity && <p className="text-red-600 p-2 font-light">This field is required</p>}
                </label>
                <label className="relative">
                  <input 
                    type="text" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.unit_price?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }
                    placeholder="Unit price"
                    {...register('unit_price',{
                      required:true,onChange: (v)=>setValue('unit_price',NumberWithCommas(v.target.value))})}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">Unit price</span>
                  {errors.unit_price && <p className="text-red-600 p-2 font-light">This field is required</p>}
                </label>
                <IF condition={isEdit}>
                  <label className="relative">
                    <input 
                      type="text" 
                      className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.actual_amount?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }
                      placeholder="Actual amount"
                      {...register('actual_amount',{
                        required:false,onChange: (v)=>setValue('actual_amount',NumberWithCommas(v.target.value))})}
                    />
                    <span className="
                      text-base text-gray-900
                      text-opacity-80  left-0 top-4
                      mx-2 px-2 absolute input-text
                      transition duration-200">Actual amount</span>
                    {errors.actual_amount && <p className="text-red-600 p-2 font-light">This field is required</p>}
                  </label>
                  <label className="relative">
                    <Controller
                      name="service_provider"
                      control={control}
                      defaultValue={null}
                      rules={{required:false}}
                      render={
                        ({field})=>(
                          <AsyncSelect
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 6,
                              border: 1,
                              colors: {
                              ...theme.colors,
                              text: 'gray',
                              primary25: 'lightgray',
                              primary: '#4b5563',
                            }})}
                            classNamePrefix="mySelect"
                            {...field}
                            isClearable
                            cacheOptions={true}
                            defaultOptions={false} 
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e.id}
                            loadOptions={getServiceProviders}
                            onInputChange={handleInputChange}
                            className={
                            `text-gray-700 text-base font-light w-full
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.service_provider?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }  
                          />
                        ) 
                      }
                    />
                    <span className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-600
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                          >Service provider</span>
                    {errors.service_provider && <p className="text-red-600 p-2 font-light">This field is required</p>}
                  </label>
                </IF>
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button onClick={()=>resetForm()} type="button" className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm">
                  <span>Cancel</span>
              </button>
              <button type="submit" className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none sm:text-sm">
                  <span>{isEdit?'Update':'Create'}</span>
              </button>
            </div>
          </form>
        </Modal>
        <DeleteModal 
          open={openDelete} 
          itemName={compname} 
          onDelete={()=>onDelete()} 
          close={()=>setOpenDelete(false)} 
        />
      </section>
    </>
  )
};
export default Budget;