import {useEffect} from 'react';
import useAuthAxios from '../../hooks/useAuthAxios';
import useAxios from '../../hooks/useAxios';
import Loader from '../../hocs/Loader';
import ReactEcharts from "echarts-for-react";
import { NumberWithCommas } from '../../utils/Helpers';
import ChatIcon from '../../utils/ChatIcon';
import UserGroupIcon from '../../utils/UserGroupIcon';
import CalenderDaysIcon from '../../utils/CalenderDaysIcon';

const ClientDashboard = ()=> {
  const app_url = "dashboard/client";
  const axios = useAuthAxios();
  const [response, error, loading, axiosFetch] = useAxios();
  const event_id = localStorage.getItem('id');

  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: 'GET',
      url: `${app_url}?event=${event_id}`,
    })
  }

  useEffect(() =>{
    getData();
    // eslint-disable-next-line
  },[])

  const optionContr = {
    title: {
      text: 'Contributions',
      subtext: 'Outstanding amount: '+ NumberWithCommas(response?.contributions?.balance),
      left: 'center',
      top:0
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient:'vertical',
      left: 'left',
      top: '20%',
      selectedMode: false
    },
    series: [
      {
        name: 'Contribution',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        startAngle: 180,
        top: '25%',
        label: {
          show: true,
          formatter(param) {
            return param.percent * 2 + '%';
          }
        },
        data: [
            {value: response?.contributions?.pledge, name: 'Pledged amount'},
            {value: response?.contributions?.paid, name: 'Collected amount'},
            {
              value: response?.contributions?.pledge + response?.contributions?.paid, 
              itemStyle: {
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        color: [
            '#dd6b66',
        '#32C5E9',
        ]
      }
    ]
  };

  const optionBudget = {
    title: {
      text: 'Budget',
      left: 'center',
      top:0
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient:'vertical',
      left: 'left',
      top: '20%',
      selectedMode: false
    },
    series: [
      {
        name: 'Budget',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        startAngle: 180,
        top: '25%',
        label: {
          show: true,
          formatter(param) {
            return param.percent * 2 + '%';
          }
        },
        data: [
            {value: response?.budget?.actual, name: 'Actual amount'},
            {value: response?.budget?.budgeted, name: 'Budgeted amount'},
            {
              value: response?.budget?.actual + response?.budget?.budgeted, 
              itemStyle: {
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        color: [
          '#91ca8c',
          '#f49f42'
        ]
      }
    ]
  }; 


  return (
    <>
      {loading && <Loader/>}
      <section className="px-2 sm:px-2 lg:px-2 w-full">
        <div className="flex flex-wrap  gap-4">
          <div 
            className="
            rounded bg-white
            overflow-hidden shadow-md
            p-4
            flex-auto w-48"
          >
            <div className="flex">
              <div className="flex-none w-20 relative 
              bg-green-500 rounded text-white ">
                <ChatIcon size={`w-20 h-20`}/>
              </div>
              <div className="flex-auto pl-6">
                <div className="flex-wrap">
                  <p className="flex-none w-full 
                    text-lg text-gray-500 pb-4">SMS Balance</p>
                  <p className="flex-none w-full 
                    text-3xl text-gray-800 
                    font-semibold">{response?.cards_data?.sms_total}</p>
                </div>
              </div>
            </div>
          </div>
          <div 
            className="
            rounded bg-white
            overflow-hidden shadow-md
            p-4
            flex-auto w-48"
          >
            <div className="flex">
              <div className="flex-none w-20 relative 
                bg-blue-500 rounded text-white ">
                <UserGroupIcon size={`w-20 h-20`}/>
              </div>
              <div className="flex-auto pl-6">
                <div className="flex-wrap">
                  <p className="flex-none w-full 
                    text-lg text-gray-500 pb-4">Contributors</p>
                  <p className="flex-none w-full 
                    text-3xl text-gray-800 
                    font-semibold">{response?.cards_data?.contributors}</p>
                </div>
              </div>
            </div>
          </div>
          <div 
            className="
            rounded bg-white
            overflow-hidden shadow-md
            p-4
            flex-auto w-48"
          >
            <div className="flex">
              <div className="flex-none w-20 relative 
              bg-pink-700 rounded text-white ">
                <CalenderDaysIcon size={`w-20 h-20`}/>
              </div>
              <div className="flex-auto pl-6">
                <div className="flex-wrap">
                  <p className="flex-none w-full 
                    text-lg text-gray-500 pb-4"
                    >
                      {`Days ${(response?.cards_data?.event_days)>0?"before":"after"} event`}
                  </p>
                  <p className="flex-none w-full 
                    text-3xl text-gray-800 font-semibold">
                      {(response?.cards_data?.event_days)> 0?response?.cards_data?.event_days:((response?.cards_data?.event_days)*-1)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap  gap-4 py-4">
          {response?.contributions?.pledge > 0 &&
          <div 
            className="
            rounded bg-white
            overflow-hidden shadow-md
            p-4
            flex-auto w-48"
          >
            <ReactEcharts option={optionContr} />
          </div>}
          {response?.budget?.budgeted > 0 &&
          <div 
            className="
            rounded bg-white
            overflow-hidden shadow-md
            p-4
            flex-auto w-48"
          >
            <ReactEcharts option={optionBudget} />
          </div>}
        </div>
      </section>
    </>
  )
}

export default ClientDashboard